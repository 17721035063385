@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap");
.row {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto; }

.row {
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px; }

@media only screen and (max-width: 1200px) {
  .row {
    padding-left: 40px;
    padding-right: 40px; } }

@media only screen and (max-width: 400px) {
  .row {
    padding-left: 25px;
    padding-right: 25px; } }

.cf, .row {
  clear: both; }

.cf:after, .row:after {
  clear: both; }

.cf:before, .row:before, .cf:after, .row:after {
  content: '';
  display: table; }

html {
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.03em;
  line-height: 1.57; }

pre {
  letter-spacing: normal;
  font-weight: 400; }

.typ--caps {
  text-transform: uppercase; }

.typ--titlecase {
  text-transform: capitalize; }

.typ--smallcaps {
  text-transform: lowercase;
  font-variant: small-caps; }

.typ--nowrap {
  white-space: nowrap; }

strong {
  font-weight: bold; }

p {
  margin-bottom: 10px; }

a {
  text-decoration: none; }

sup {
  vertical-align: super;
  font-size: smaller; }

sub {
  font-size: 6px;
  padding-left: 3px; }

blockquote {
  padding-left: 30px;
  border-left: 1px solid #000000; }

.typ--underline {
  text-decoration: underline; }

.typ--actionable {
  cursor: pointer; }

.typ--truncate {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; }

.typ--center {
  text-align: center; }

.typ--left {
  text-align: left; }

.typ--right {
  text-align: right; }

.typ--bold {
  font-weight: bold; }

.typ--medium {
  font-weight: medium; }

h1, .typ--h1 {
  font-size: 18px;
  letter-spacing: 0.05em;
  font-weight: normal; }

h2, .typ--h2 {
  font-size: 15px;
  letter-spacing: 0.03em;
  font-weight: 500; }

h3, .typ--h3 {
  font-size: 12px;
  letter-spacing: 0.03em;
  font-weight: 600; }

.typ--link {
  text-decoration: underline;
  cursor: pointer; }

.disable-scroll {
  overflow: hidden; }

.layout--left {
  float: left; }

.layout--right {
  float: right; }

.layout--relative {
  position: relative; }

.layout--block {
  display: block; }

.page-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.page-content .content {
  flex: 1; }

.content > .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -20vh; }

.row {
  width: 100%;
  position: relative;
  clear: both;
  float: none; }

.gridspacer {
  height: 1px; }

.col--1 {
  width: calc(100% * 1 / 12 - 40px * (1 - 1 / 12));
  float: left;
  margin-right: 40px; }

.col--1:last-of-type {
  margin-right: 0; }

.col--2 {
  width: calc(100% * 2 / 12 - 40px * (1 - 2 / 12));
  float: left;
  margin-right: 40px; }

.col--2:last-of-type {
  margin-right: 0; }

.col--3 {
  width: calc(100% * 3 / 12 - 40px * (1 - 3 / 12));
  float: left;
  margin-right: 40px; }

.col--3:last-of-type {
  margin-right: 0; }

.col--4 {
  width: calc(100% * 4 / 12 - 40px * (1 - 4 / 12));
  float: left;
  margin-right: 40px; }

.col--4:last-of-type {
  margin-right: 0; }

.col--5 {
  width: calc(100% * 5 / 12 - 40px * (1 - 5 / 12));
  float: left;
  margin-right: 40px; }

.col--5:last-of-type {
  margin-right: 0; }

.col--6 {
  width: calc(100% * 6 / 12 - 40px * (1 - 6 / 12));
  float: left;
  margin-right: 40px; }

.col--6:last-of-type {
  margin-right: 0; }

.col--7 {
  width: calc(100% * 7 / 12 - 40px * (1 - 7 / 12));
  float: left;
  margin-right: 40px; }

.col--7:last-of-type {
  margin-right: 0; }

.col--8 {
  width: calc(100% * 8 / 12 - 40px * (1 - 8 / 12));
  float: left;
  margin-right: 40px; }

.col--8:last-of-type {
  margin-right: 0; }

.col--9 {
  width: calc(100% * 9 / 12 - 40px * (1 - 9 / 12));
  float: left;
  margin-right: 40px; }

.col--9:last-of-type {
  margin-right: 0; }

.col--10 {
  width: calc(100% * 10 / 12 - 40px * (1 - 10 / 12));
  float: left;
  margin-right: 40px; }

.col--10:last-of-type {
  margin-right: 0; }

.col--11 {
  width: calc(100% * 11 / 12 - 40px * (1 - 11 / 12));
  float: left;
  margin-right: 40px; }

.col--11:last-of-type {
  margin-right: 0; }

.col--12 {
  width: calc(100% * 12 / 12 - 40px * (1 - 12 / 12));
  float: left;
  margin-right: 40px; }

.col--12:last-of-type {
  margin-right: 0; }

.col--last {
  margin-right: 0; }

.col--center {
  margin-left: auto;
  margin-right: auto;
  float: none; }

.m0 {
  margin: 00px; }

.mt0 {
  margin-top: 00px; }

.mr0 {
  margin-right: 00px; }

.mb0 {
  margin-bottom: 00px; }

.ml0 {
  margin-left: 00px; }

.mx0 {
  margin-left: 00px;
  margin-right: 00px; }

.my0 {
  margin-top: 00px;
  margin-bottom: 00px; }

.p0 {
  padding: 00px; }

.pt0 {
  padding-top: 00px; }

.pr0 {
  padding-right: 00px; }

.pb0 {
  padding-bottom: 00px; }

.pl0 {
  padding-left: 00px; }

.px0 {
  padding-left: 00px;
  padding-right: 00px; }

.py0 {
  padding-top: 00px;
  padding-bottom: 00px; }

.m1 {
  margin: 10px; }

.mt1 {
  margin-top: 10px; }

.mr1 {
  margin-right: 10px; }

.mb1 {
  margin-bottom: 10px; }

.ml1 {
  margin-left: 10px; }

.mx1 {
  margin-left: 10px;
  margin-right: 10px; }

.my1 {
  margin-top: 10px;
  margin-bottom: 10px; }

.p1 {
  padding: 10px; }

.pt1 {
  padding-top: 10px; }

.pr1 {
  padding-right: 10px; }

.pb1 {
  padding-bottom: 10px; }

.pl1 {
  padding-left: 10px; }

.px1 {
  padding-left: 10px;
  padding-right: 10px; }

.py1 {
  padding-top: 10px;
  padding-bottom: 10px; }

.m2 {
  margin: 20px; }

.mt2 {
  margin-top: 20px; }

.mr2 {
  margin-right: 20px; }

.mb2 {
  margin-bottom: 20px; }

.ml2 {
  margin-left: 20px; }

.mx2 {
  margin-left: 20px;
  margin-right: 20px; }

.my2 {
  margin-top: 20px;
  margin-bottom: 20px; }

.p2 {
  padding: 20px; }

.pt2 {
  padding-top: 20px; }

.pr2 {
  padding-right: 20px; }

.pb2 {
  padding-bottom: 20px; }

.pl2 {
  padding-left: 20px; }

.px2 {
  padding-left: 20px;
  padding-right: 20px; }

.py2 {
  padding-top: 20px;
  padding-bottom: 20px; }

.m3 {
  margin: 30px; }

.mt3 {
  margin-top: 30px; }

.mr3 {
  margin-right: 30px; }

.mb3 {
  margin-bottom: 30px; }

.ml3 {
  margin-left: 30px; }

.mx3 {
  margin-left: 30px;
  margin-right: 30px; }

.my3 {
  margin-top: 30px;
  margin-bottom: 30px; }

.p3 {
  padding: 30px; }

.pt3 {
  padding-top: 30px; }

.pr3 {
  padding-right: 30px; }

.pb3 {
  padding-bottom: 30px; }

.pl3 {
  padding-left: 30px; }

.px3 {
  padding-left: 30px;
  padding-right: 30px; }

.py3 {
  padding-top: 30px;
  padding-bottom: 30px; }

.m4 {
  margin: 40px; }

.mt4 {
  margin-top: 40px; }

.mr4 {
  margin-right: 40px; }

.mb4 {
  margin-bottom: 40px; }

.ml4 {
  margin-left: 40px; }

.mx4 {
  margin-left: 40px;
  margin-right: 40px; }

.my4 {
  margin-top: 40px;
  margin-bottom: 40px; }

.p4 {
  padding: 40px; }

.pt4 {
  padding-top: 40px; }

.pr4 {
  padding-right: 40px; }

.pb4 {
  padding-bottom: 40px; }

.pl4 {
  padding-left: 40px; }

.px4 {
  padding-left: 40px;
  padding-right: 40px; }

.py4 {
  padding-top: 40px;
  padding-bottom: 40px; }

.m5 {
  margin: 50px; }

.mt5 {
  margin-top: 50px; }

.mr5 {
  margin-right: 50px; }

.mb5 {
  margin-bottom: 50px; }

.ml5 {
  margin-left: 50px; }

.mx5 {
  margin-left: 50px;
  margin-right: 50px; }

.my5 {
  margin-top: 50px;
  margin-bottom: 50px; }

.p5 {
  padding: 50px; }

.pt5 {
  padding-top: 50px; }

.pr5 {
  padding-right: 50px; }

.pb5 {
  padding-bottom: 50px; }

.pl5 {
  padding-left: 50px; }

.px5 {
  padding-left: 50px;
  padding-right: 50px; }

.py5 {
  padding-top: 50px;
  padding-bottom: 50px; }

.m6 {
  margin: 60px; }

.mt6 {
  margin-top: 60px; }

.mr6 {
  margin-right: 60px; }

.mb6 {
  margin-bottom: 60px; }

.ml6 {
  margin-left: 60px; }

.mx6 {
  margin-left: 60px;
  margin-right: 60px; }

.my6 {
  margin-top: 60px;
  margin-bottom: 60px; }

.p6 {
  padding: 60px; }

.pt6 {
  padding-top: 60px; }

.pr6 {
  padding-right: 60px; }

.pb6 {
  padding-bottom: 60px; }

.pl6 {
  padding-left: 60px; }

.px6 {
  padding-left: 60px;
  padding-right: 60px; }

.py6 {
  padding-top: 60px;
  padding-bottom: 60px; }

.m7 {
  margin: 70px; }

.mt7 {
  margin-top: 70px; }

.mr7 {
  margin-right: 70px; }

.mb7 {
  margin-bottom: 70px; }

.ml7 {
  margin-left: 70px; }

.mx7 {
  margin-left: 70px;
  margin-right: 70px; }

.my7 {
  margin-top: 70px;
  margin-bottom: 70px; }

.p7 {
  padding: 70px; }

.pt7 {
  padding-top: 70px; }

.pr7 {
  padding-right: 70px; }

.pb7 {
  padding-bottom: 70px; }

.pl7 {
  padding-left: 70px; }

.px7 {
  padding-left: 70px;
  padding-right: 70px; }

.py7 {
  padding-top: 70px;
  padding-bottom: 70px; }

.m8 {
  margin: 80px; }

.mt8 {
  margin-top: 80px; }

.mr8 {
  margin-right: 80px; }

.mb8 {
  margin-bottom: 80px; }

.ml8 {
  margin-left: 80px; }

.mx8 {
  margin-left: 80px;
  margin-right: 80px; }

.my8 {
  margin-top: 80px;
  margin-bottom: 80px; }

.p8 {
  padding: 80px; }

.pt8 {
  padding-top: 80px; }

.pr8 {
  padding-right: 80px; }

.pb8 {
  padding-bottom: 80px; }

.pl8 {
  padding-left: 80px; }

.px8 {
  padding-left: 80px;
  padding-right: 80px; }

.py8 {
  padding-top: 80px;
  padding-bottom: 80px; }

.m9 {
  margin: 90px; }

.mt9 {
  margin-top: 90px; }

.mr9 {
  margin-right: 90px; }

.mb9 {
  margin-bottom: 90px; }

.ml9 {
  margin-left: 90px; }

.mx9 {
  margin-left: 90px;
  margin-right: 90px; }

.my9 {
  margin-top: 90px;
  margin-bottom: 90px; }

.p9 {
  padding: 90px; }

.pt9 {
  padding-top: 90px; }

.pr9 {
  padding-right: 90px; }

.pb9 {
  padding-bottom: 90px; }

.pl9 {
  padding-left: 90px; }

.px9 {
  padding-left: 90px;
  padding-right: 90px; }

.py9 {
  padding-top: 90px;
  padding-bottom: 90px; }

.m10 {
  margin: 100px; }

.mt10 {
  margin-top: 100px; }

.mr10 {
  margin-right: 100px; }

.mb10 {
  margin-bottom: 100px; }

.ml10 {
  margin-left: 100px; }

.mx10 {
  margin-left: 100px;
  margin-right: 100px; }

.my10 {
  margin-top: 100px;
  margin-bottom: 100px; }

.p10 {
  padding: 100px; }

.pt10 {
  padding-top: 100px; }

.pr10 {
  padding-right: 100px; }

.pb10 {
  padding-bottom: 100px; }

.pl10 {
  padding-left: 100px; }

.px10 {
  padding-left: 100px;
  padding-right: 100px; }

.py10 {
  padding-top: 100px;
  padding-bottom: 100px; }

.btn {
  height: 35px;
  min-width: 85px;
  background-color: #000000;
  border: 1px solid #000000;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px; }

.btn--action {
  min-width: 97px;
  height: 26px;
  background-color: #000000; }

.btn--transparent {
  background-color: transparent;
  color: #000000;
  box-sizing: border-box; }

.btn--disabled {
  background-color: #D8DAE0;
  border: none;
  color: #B8BDC5;
  pointer-events: none; }

.form__input {
  height: 44px;
  border: 1px solid #b8bdc5;
  background-color: transparent;
  color: inherit;
  padding: 0 15px; }

.form__label {
  color: #9A9EA7;
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  margin-bottom: 7px;
  font-weight: bold; }
